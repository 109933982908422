import React from "react";
import { Helmet } from "react-helmet";

const ValuesPage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Values | Dotter+Pop</title>
        <meta property="og:title" content="Values | Dotter+Pop" />
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div className="flex justify-center items-center bg-blue-300">
        <h1 className="text-5xl uppercase font-black">Values</h1>
      </div>

      <div className="relative overflow-hidden bg-white py-8">
        <div className="relative py-2 px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-lg">
            <h2>
              <span className="mt-2 block text-center
               text-2xl font-bold leading-8 tracking-tight 
               text-gray-900 sm:text-3xl">
                Our Values
              </span>
            </h2>
            <div className=" text-2xl text-center">
      <div className="pt-4 flex items-center justify-center ">
        <ul className="list-inside ">  
          <li className="m-2">Stay Healthy </li>
          <li className="m-2">Be Helpful </li>
          <li className="m-2">Lifelong Learning </li>
          <li className="m-2">Candor</li>
          <li className="m-2">Good Citizenship </li>
          <li className="m-2">Fairness amd Equity</li>
          <li className="m-2">Be Highly Productive </li>
        </ul>
      </div>
    </div>
            
          </div>
        </div>
      </div>

    
    </div>
  );
};

export default ValuesPage;
